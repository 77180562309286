













































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { nextTick } from '@/shareds/utils'
import { arrayObrigatorio } from '@/shareds/regras-de-form'
import { FiltroDeDocumentosFiscais } from '@/models/relatorios/FiltroDeDocumentosFiscais'

@Component
export default class DialogoDeFiltroDeRelatorioDeDocumentos extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({type: Boolean, default: false}) carregando!: boolean
	@Prop({type: String, default: null}) lojaId!: string
	@Prop({ type: Object, 
		default: {
			lojaId: null,
			dataInicial: null,
			dataFinal: null,
			serie: '',
			ambientes: ['Produção'],
			notaInicial: null,
			notaFinal: null,
			tpnf: 'Entrada e Saída',
			exibirSomenteNotasValidas: false,
			somenteCompras:false,
		},
	})
	filtro!: FiltroDeDocumentosFiscais

	mostra: boolean | null = null
	arrayObrigatorio = arrayObrigatorio

	mostrar() {
		this.mostra = true
	}

	ocultar(){
		this.mostra = false
	}

	get ambientes() {
		return ['Produção', 'Homologação']
	}

	alteraTpnf(value: boolean) {
		if (value) this.filtro.tpnf = 'Entrada'
	}

	alteraSomenteCompras(value: string) {
		if(value == 'Saída' || value == 'Entrada e Saída') this.filtro.somenteCompras = false
	}

	async aplicarFiltro() {
		if (!this.form.validate()) return
		await nextTick()
		this.$emit('buscar', this.filtro)
	}
}
